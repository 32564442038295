@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

:root {
  --font-color: rgb(255, 255, 255);
  --gradient-main: rgb(0, 0, 0);
  --gradient-left: rgb(0, 0, 0);
  --gradient-right: rgb(0, 0, 0);
  --primary: rgb(4, 102, 255);
  --primary-light: rgb(40, 124, 254);
  --overlay: rgba(255, 255, 255, 0.25);
  --white: #eee;
}

body,
html {
  font-family: 'Space Mono', monospace;
  font-weight: 700;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;
}

body {
  align-items: center;
  background-blend-mode: screen;
  background-color: var(--gradient-main);
  background-repeat: no-repeat;
  display: grid;
  justify-content: center;
  transition: background-color 500ms;
}

.floating-widget {
  backdrop-filter: blur(12px);
  background: var(--overlay);
  border: 1px solid var(--overlay);
  border-radius: 0.5rem;
  display: inline-block;
  font-size: 3rem;
  padding: 0;
  text-align: center;

  .text {
    padding: 0;
    margin: 1rem;
  }

  .code {
    text-transform: uppercase;
  }

  .buttons {
    display: none; /* TODO use: flex */
    justify-content: center;
  }

  .button {
    background: var(--overlay);
    border: 0;
    border-left: 1px solid var(--overlay);
    font-family: inherit;
    font-weight: 400;
    padding: 0.5rem;
    color: var(--font-color);

    &:first-of-type {
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:last-of-type {
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &:hover {
      background-image: linear-gradient(var(--primary-light), var(--primary));
      color: var(--white);
    }
  }
}
